import React, { useState } from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import styled, { css } from 'styled-components'
import { motion } from 'framer-motion'
import ProgressiveImage from 'react-progressive-image'

import { Layout, Newsletter } from '../components'
import {
    container,
    padding,
    colours,
    type,
    bgImage,
    bgIcon,
} from '../styles/global'
import { media } from '../styles/utils'
import { parseACF, getImageCrop } from '../utils'

const Contact = (props) => {
    const data = parseACF(useStaticQuery(query), 'allWordpressInfopages')

    // Team
    const renderTeam = (props) => {
        if (!data.our_team) return

        const items = data.our_team.map((item, i) => {
            return (
                <>
                    <Member key={i}>
                        {item.image && (
                            <Image
                                key={item.image.sizes.medium2}
                                src={item.image.sizes.medium2}
                            >
                                {(src, loading) => {
                                    return (
                                        <BGImage
                                            image={src}
                                            style={{ opacity: loading ? 0 : 1 }}
                                        />
                                    )
                                }}
                            </Image>
                        )}

                        <Meta>
                            <Name>{item.name}</Name>
                            <Position>{item.position}</Position>
                        </Meta>

                        <Email
                            as={'a'}
                            href={`mailto:${item.email}`}
                            target="_blank"
                        >
                            {item.email}
                        </Email>
                        <Phone
                            as={'a'}
                            href={`tel:${item.phone}`}
                            target="_blank"
                        >
                            {item.phone}
                        </Phone>
                    </Member>
                </>
            )
        })

        return (
            <Team>
                <Heading>Our Team</Heading>
                <TeamList>{items}</TeamList>
            </Team>
        )
    }

    return (
        <Layout meta={data && data.seo} useBlackCharacters={true}>
            <Container>
                <Description
                    dangerouslySetInnerHTML={{
                        __html: data.description,
                    }}
                />

                <Enquiries>
                    <Heading
                        dangerouslySetInnerHTML={{
                            __html: data.info_heading,
                        }}
                    />
                    <Text
                        dangerouslySetInnerHTML={{
                            __html: data.info_text,
                        }}
                    />
                </Enquiries>

                {renderTeam()}
            </Container>
        </Layout>
    )
}

// Shared

const Description = styled.div``

const Enquiries = styled.div``
const Heading = styled.div``
const Text = styled.div``

const TeamList = styled.div``
const Member = styled.div``
const Meta = styled.div``
const Name = styled.p``
const Position = styled.p``
const Email = styled.a``
const Phone = styled.div``

// Base

const Image = styled(ProgressiveImage)`
    overflow: hidden;
`
const BGImage = styled.div`
    background-image: url(${(props) => props.image});
    ${bgIcon};
    transition: opacity 0.45s ease;
`

// Container

const Container = styled.div`
    ${container}
    ${padding}
	padding-top: 120px;
    padding-bottom: 60px;
    flex-direction: row;
    flex-wrap: wrap;
    background: white;
	min-height: 100vh;

    ${media.tablet`
		padding-top: 80px;
	`}

	${media.phone`
		display: flex;
		flex-wrap: nowrap;
		flex-direction: column;
	`}

    ${Description} {
        flex-basis: calc(66.66% - 80px);
        color: ${colours.red};
        padding-right: 30px;

		${media.tablet`
			padding-right: 20px;
		`}

		${media.phone`
			width: 100%;
		`}
		
    }

    ${Enquiries} {
        flex-basis: 30%;

        ${Heading} {
            color: ${colours.red};
            padding-bottom: 20px;
            font-size: 24px;

            ${media.tablet`
				padding-bottom: 20px;
			`}
        }

        ${Text} {
            p {
                font-size: 24px;
                margin: 0;
                padding-bottom: 8px;
            }

            a {
                color: ${colours.black};
                font-size: 24px;

                &:hover {
                    color: ${colours.red};
                }
            }
        }
    }
`

// Team

const Team = styled.div`
    flex-basis: 100%;
    padding: 20px 0;

    ${Heading} {
        color: ${colours.red};
        padding-bottom: 20px;
        font-size: 24px;

        ${media.tablet`
			padding-bottom: 20px;
		`}
    }

    ${TeamList} {
        display: flex;
        flex-wrap: wrap;

        ${media.phone`
			flex-direction: column;
		`}

        ${Member} {
            flex: 0 1 calc(33.33% - 40px);
            display: flex;
            flex-direction: column;

            ${media.tablet`
				flex: 0 1 calc(50% - 40px);
			`}

            ${BGImage} {
                width: 200px;
                height: 200px;

                ${media.phone`
					margin-top: 20px;
				`}
            }

            ${Meta} {
                display: flex;
                flex-direction: column;
                color: ${colours.red};
                padding: 8px 0;

                p {
                    margin: 0;
                    font-size: 24px;
                }
            }

            ${Email}, ${Phone} {
                font-size: 24px;

                color: ${colours.black};

                &:hover {
                    color: ${colours.red};
                }
            }
        }
    }
`

// Query

export const query = graphql`
    query {
        allWordpressInfopages(filter: { slug: { eq: "contact" } }) {
            nodes {
                acf_json
            }
        }
    }
`

export default Contact
